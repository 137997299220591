import React, { useRef, useState } from 'react'
import { Canvas, useFrame } from "@react-three/fiber";
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Environment, OrbitControls, Sky, Stats, AdaptiveDpr } from '@react-three/drei'
import { Suspense } from "react"
import { EffectComposer, DepthOfField, Bloom, Noise, Vignette } from '@react-three/postprocessing'

import Loader from './Loader';

import model from "./MS986sterownikMini.glb"
import hdr from "./industrial_workshop_foundry_2k.hdr"

// function Box(props) {
//     // This reference gives us direct access to the THREE.Mesh object
//     const ref = useRef()
//     // Hold state for hovered and clicked events
//     const [hovered, hover] = useState(false)
//     const [clicked, click] = useState(false)
//     // Subscribe this component to the render-loop, rotate the mesh every frame
//     useFrame((state, delta) => (ref.current.rotation.x += delta))
//     // Return the view, these are regular Threejs elements expressed in JSX
//     return (
//       <mesh
//         {...props}
//         ref={ref}
//         scale={clicked ? 1.5 : 1}
//         onClick={(event) => click(!clicked)}
//         onPointerOver={(event) => hover(true)}
//         onPointerOut={(event) => hover(false)}>
//         <boxGeometry args={[1, 1, 1]} />
//         <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
//       </mesh>
//     )
//   }

function Viewport() {
    const gltf = useLoader(GLTFLoader, model)
    //gltf.scene.rotation.x=-Math.PI/2
    const ref = useRef()
    // useFrame((state, delta) => (ref.current.rotation.x += delta))
    return (
        <Canvas camera={{ position: [0, 0, 0.8], fov: 50, zoom: 4 }} performance={{ min: 1 }}>
            <OrbitControls
                enableDamping={true}
                minDistance={0.3}
                maxDistance={2}
                />
                {/* <ambientLight intensity={0.2} />
            <pointLight position={[0.2, 0.2, 0.2]} intensity={1} /> */}
            {/* <Box position={[-1.2, 0, 0]} />
            <Box position={[1.2, 0, 0]} /> */}
            <primitive ref={ref} object={gltf.scene} />
            <Environment background={false} files={hdr} />
            {/* <EffectComposer>
                <DepthOfField focusDistance={0} focalLength={0.02} bokehScale={4} />
                <Vignette eskil={false} offset={0.5} darkness={0.5} />
            </EffectComposer> */}
            {/* <Stats /> */}
            {/* <AdaptiveDpr pixelated /> */}
        </Canvas>
    );
}

function Scene() {
    return (
        <Suspense fallback={<Loader />}>
            <Viewport />
        </Suspense>
    )
}

export default Scene;