import { Container } from "react-bootstrap"

import Scene from "./Scene"

function App() {
  return (
    <Container className="px-0 bg-dark" style={{ "position": "relative", "height": "100vh", maxWidth: "100%" }}>
      <Scene />
    </Container>
  );
}

export default App;
