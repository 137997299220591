import { Container, Spinner } from "react-bootstrap"

function Loader() {
  return (
    <Container className="d-flex flex-column align-items-center bg-dark" style={{height: "100vh", maxWidth: "100%" }}>
      <div style={{flexShrink: "1", width:"100%", height:"25%", minHeight: 0}}></div>
      <div className="d-flex flex-column align-items-center" style={{flexGrow: "3"}}>
      <Spinner variant="danger" style={{width: "3rem", height: "3rem"}} />
      <h3 className="text-light mt-3">Loading model</h3>
      </div>
    </Container>
  );
}

export default Loader;